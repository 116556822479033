import { IoChevronBackOutline } from "react-icons/io5";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import NavTab from "../NavTabs";
import { useSeniority } from "../../store/seniority.state";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import { useRunOnceExt } from "../../hooks/runOnce";
import { InfinitySpin } from "react-loader-spinner";

export const TopBar = ({
  globalLoading,
  setEstimationParams,
}: {
  globalLoading: boolean;
  setEstimationParams: (input: {
    locationId?: string;
    seniority?: number;
  }) => void;
}) => {
  const navigate = useNavigate();

  const { seniorities, activeId, setActiveId } = useSeniority();
  const { setDoctorFilterSeniority } = useEstimationContext();
  const { dateHash } = useParams<{ dateHash: string }>();

  const { resetStates } = useEstimationContext();
  const [searchParams] = useSearchParams();


  useRunOnceExt(() => {
    if (searchParams.get("seniority")) {
      setActiveId(Number(searchParams.get("seniority")));
      setDoctorFilterSeniority(Number(searchParams.get("seniority")));
    } else {
      setEstimationParams({
        seniority: activeId,
      });
    }
  });

  return (
    <div className="w-full bg-white items-center flex justify-between px-10">
      <div
        className="flex items-center bg-gray2 border-gray cursor-pointer rounded h-[25px] w-[25px]"
        onClick={() => {
          navigate(
            `/dashboard?locationId=${searchParams.get(
              "locationId"
            )}&seniority=${searchParams.get("seniority")}&dateHash=${
              dateHash ?? ""
            }`
          );
        }}
      >
        <IoChevronBackOutline size={20} className="text-black mx-auto" />
      </div>
      <NavTab
        tabs={seniorities}
        tabType="neumorphism"
        activeId={activeId}
        onChange={(id) => {
          setEstimationParams({
            seniority: id,
          });
          resetStates(id);
          setActiveId(id);
        }}
      />
      {globalLoading ? (
        <div className=" text-secondary flex text-base font-medium items-center gap-2">
          <InfinitySpin width="100" color="#67823A" />
          Syncing...
        </div>
      ) : (
        <div className=" text-secondary flex text-base font-medium items-center gap-2">
          Synced.
        </div>
      )}
    </div>
  );
};
