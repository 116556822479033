import React, { useEffect, useState } from "react"
import RulesCard from "../Cards/RulesCard"
import api from "../../api/api"
import { useSeniority } from "../../store/seniority.state"
import { MutatingDots } from "react-loader-spinner"
import Input from "../Input"
import Typography from "../Typography"
import Button from "../Button"
import { toast } from "react-toastify"
import { useModal } from "../../hooks/useModal"


interface GroupRulesModalProps {
  groupId: string
}

const GroupRulesModal = (props: GroupRulesModalProps) => {
  const { groupId } = props
  const { activeId: activeSeniorityId } = useSeniority()
  const { closeModal } = useModal()

  const [minRestBetweenShifts, setMinRestBetweenShifts] = useState<number>(0)
  const [maxHoursPerWeek, setMaxHoursPerWeek] = useState<number>(0)
  const [offsPerWeek, setOffsPerWeek] = useState<number>(0)

  // all checked booleans
  const [minRestBetweenShiftsChecked, setMinRestBetweenShiftsChecked] =
    useState<boolean>(false)
  const [maxHoursPerWeekChecked, setMaxHoursPerWeekChecked] =
    useState<boolean>(false)
  const [offsPerWeekChecked, setOffsPerWeekChecked] = useState<boolean>(false)

  const {
    fetchFn: getRules,
    isLoading: isRulesLoading,
    data: rulesData,
  } = api.rules.useGetRulesByGroupId()

  const { fetchFn: applyRules, isLoading: isRuleApplyLoading } =
    api.rules.useApplyRule()

  useEffect(() => {
    getRules({
      params: {
        groupId,
        seniority: activeSeniorityId,
      },
    })
  }, [])

  useEffect(() => {
    if (rulesData?.minRestBetweenShifts) {
      setMinRestBetweenShifts(rulesData?.minRestBetweenShifts?.value)
      setMinRestBetweenShiftsChecked(rulesData?.minRestBetweenShifts?.enabled)
    }
    if (rulesData?.maxHoursPerWeek) {
      setMaxHoursPerWeek(rulesData?.maxHoursPerWeek?.value)
      setMaxHoursPerWeekChecked(rulesData?.maxHoursPerWeek?.enabled)
    }
    if (rulesData?.offsPerWeek) {
      setOffsPerWeek(rulesData?.offsPerWeek?.value)
      setOffsPerWeekChecked(rulesData?.offsPerWeek?.enabled)
    }
  }, [rulesData])

  return (
    <div className="bg-white !w-screen grid grid-cols-2 gap-8 outer-shadow max-w-screen-xl p-7 rounded-lg  min-h-[30rem]">
      <div className="text-black3 col-span-2 text-base font-semibold">
        Group Rules
      </div>
      {isRulesLoading ? (
        <div className="flex flex-col col-span-1 h-full mt-10 text-center items-center justify-center text-black3 text-base font-semibold">
          <MutatingDots
            height={130}
            width="100"
            color="#67823A"
            secondaryColor="#67823A"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : null}
      {!isRulesLoading ? (
        <>
          <div className=" col-span-1">
            <RulesCard
              id={1}
              isChecked={minRestBetweenShiftsChecked}
              onChange={(e) => {
                setMinRestBetweenShiftsChecked(e.target.checked)
              }}
              label={{
                name: "Select Rest Time (In Hours)",
                content: (
                  <div className="">
                    {" "}
                    <Input
                      type="number"
                      min="0"
                      className="!border-2 !h-10 !text-xs !rounded-2xl"
                      value={minRestBetweenShifts}
                      onChange={(e) => {
                        setMinRestBetweenShifts(Number(e.target.value))
                      }}
                    />
                  </div>
                ),
              }}
              name="Minimum Rest Between Shifts"
            />
          </div>
          <div className=" col-span-1">

            <RulesCard
              id={1}
              isChecked={maxHoursPerWeekChecked}
              onChange={(e) => {
                setMaxHoursPerWeekChecked(e.target.checked)
              }}
              label={{
                name: "Total Working Hours Per Week",
                content: (
                  <div className="">
                    {" "}
                    <Input
                      type="number"
                      min="0"
                      className="!border-2 !h-10 !text-xs !rounded-2xl"
                      value={maxHoursPerWeek}
                      onChange={(e) => {
                        setMaxHoursPerWeek(Number(e.target.value))
                      }}
                    />
                  </div>
                ),
              }}
              name="Hours Per Week"
            />
          </div>
          <div className=" col-span-1">
            <RulesCard
              id={1}
              isChecked={offsPerWeekChecked}
              onChange={(e) => {
                setOffsPerWeekChecked(e.target.checked)
              }}
              label={{
                name: "Total Offs Per Week (In Day)",
                content: (
                  <div className="">
                    {" "}
                    <Input
                      type="number"
                      min="0"
                      className="!border-2 !h-10 !text-xs !rounded-2xl"
                      value={offsPerWeek}
                      onChange={(e) => {
                        setOffsPerWeek(Number(e.target.value))
                      }}
                    />
                  </div>
                ),
              }}
              name="Number of Offs Per Week"
            />
          </div>

          <div className="w-full flex items-center justify-center gap-6 col-span-2">
            <Button
              onClick={closeModal}
              // disabled={false}
              className="!w-60  flex justify-center px-6 !bg-transparent !border-2 border-secondary text-center !text-secondary !rounded-xl !h-11"
            >
              Cancel
            </Button>
            <Button
              disabled={isRuleApplyLoading || isRulesLoading}
              className="!w-60  !h-11 bg-secondary text-center flex items-center justify-center text-white !rounded-xl"
              onClick={async () => {
                const apply = await applyRules({
                  data: {
                    forGroups: [groupId],
                    rule: {
                      minRestBetweenShifts: {
                        enabled: minRestBetweenShiftsChecked,
                        value: minRestBetweenShifts,
                      },
                      maxHoursPerWeek: {
                        enabled: maxHoursPerWeekChecked,
                        value: maxHoursPerWeek,
                      },
                      offsPerWeek: {
                        enabled: offsPerWeekChecked,
                        value: offsPerWeek,
                      },
                    },
                    seniority: activeSeniorityId,
                  },
                })

                const { isSuccess, isError, error } = apply.unwrap()

                if (isSuccess) {
                  toast.success("Rules Applied Successfully")
                  getRules({
                    params: {
                      groupId,
                      seniority: activeSeniorityId,
                    },
                  })
                }

                if (isError) {
                  toast.error(error?.response?.data?.message)
                }
              }}
            >
              <Typography tag="span" className="font-semibold">
                {!isRuleApplyLoading ? "Save" : "Applying Rules..."}
              </Typography>
            </Button>
          </div>
        </>) : null
      }
    </div >
  )
}

export default GroupRulesModal
