import { DateTime } from "luxon";
import { TIME_ZONE } from "../constants";

export const getDateFromHash = (dateHash: string): Date =>
  new Date(Number(window.atob(dateHash)));

export const getHashFromDate = (date: Date): string =>
  window.btoa(date.getTime().toString());

export const incrementMonth = (month: number, year: number) => {
  let newMonth = month;
  let newYear = year;
  if (month === 12) {
    newMonth = 1;
    newYear += 1;
  } else {
    newMonth += 1;
  }
  return { month: newMonth, year: newYear };
};

export const decrementMonth = (month: number, year: number) => {
  let newMonth = month;
  let newYear = year;
  if (month === 1) {
    newMonth = 12;
    newYear -= 1;
  } else {
    newMonth -= 1;
  }
  return { month: newMonth, year: newYear };
};

export function convertToAmPm(dateString: string): string {
  const date = new Date(dateString);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

export const getDateRange = (date: Date) => {
  const nextDate = DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .plus({ day: 1 })
    .toJSDate();
  const prevDate = DateTime.fromJSDate(date)
    .setZone(TIME_ZONE)
    .plus({ day: -1 })
    .toJSDate();

  return { prevDate, activeDate: date, nextDate };
};

/**
 * @description This is a special util function that can add multiple ranges in the same array (Used in)
 * @param date 
 * @param dates 
 * @returns 
 */
export const addRemoveDateRanges = (date: Date, dates: Date[]) => {
  if (dates.length > 0) {
    let modifier = 0;
    if (dates.find((date_) => date_.getTime() < date.getTime())) {
      modifier = -1;
    } else if (dates.find((date_) => date_.getTime() > date.getTime())) {
      modifier = 1;
    }
    const datesToAdd = [date];
    let dayCount = 1;
    while (
      !dates.find((date_) =>
        DateTime.fromJSDate(date_)
          .setZone(TIME_ZONE)
          .equals(
            DateTime.fromJSDate(date)
              .setZone(TIME_ZONE)
              .plus({ day: modifier * dayCount })
          )
      )
    ) {
      datesToAdd.push(
        DateTime.fromJSDate(date)
          .setZone(TIME_ZONE)
          .plus({ day: modifier * dayCount })
          .toJSDate()
      );
      dayCount++;
    }
    return [...dates, ...datesToAdd];
  }
  return [date];
};
