import React, { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import DefaultLayout from "../layouts/Default"
import { ThreeDots } from "react-loader-spinner"
import Input from "../components/Input"
import { useFormik } from "formik"
import { z } from "zod"
import Button from "../components/Button"
import api from "../api/api"
import { toast } from "react-toastify"

interface OtpForm {
  email: string
  otp: string
}

const Otp = () => {
  const { state } = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()

  const { fetchFn: verifyOtp } = api.auth.useVerifyOtp()

  useEffect(() => {
    if (!state?.email) {
      navigate("/")
    }
  }, [])
  const formik = useFormik<OtpForm>({
    initialValues: {
      email: state?.email ?? "",
      otp: "",
    },
    validate: (values) => {
      // 4digit only
      const schema = z.object({
        email: z
          .string({ required_error: "Email is required!" })
          .email("Invalid email!")
          .nonempty("Email is required!"),
        otp: z
          .string({ required_error: "OTP is required!" })
          .min(4, "OTP must be 4 digits!")
          .max(4, "OTP must be 4 digits!")
          .nonempty("OTP is required!"),
      })

      const result = schema.safeParse(values)

      if (!result.success) {
        const errors = Object.entries(
          result.error.flatten().fieldErrors
        ).reduce((acc, [key, value]) => {
          acc[key] = value[0]
          return acc
        }, {} as Record<string, string>)
        return errors
      }
      return {}
    },
    onSubmit: async (values) => {
      setIsLoading(true)

      const verify = await verifyOtp({
        data: {
          email: values.email,
          otp: values.otp,
        },
      })

      const { isSuccess, data, isError, error } = verify.unwrap()

      if (isSuccess) {
        toast.success(data?.message ?? "OTP verified successfully!")
        navigate("/reset-password", {
          state: { email: values.email, token: data.token },
        })
      }

      if (isError) {
        toast.error(error?.message ?? "Something went wrong!")
      }
    },
  })

  return (
    <DefaultLayout>
      <div className="!max-w-xl py-8 px-12 w-full rounded-xl bg-white h-fit outer-shadow">
        <div className="mb-8 dm font-bold text-4xl text-black">
          Forgot Password!
        </div>
        {/* <div className="mb-16 flex justify-center"></div> */}
        <form onSubmit={formik.handleSubmit}>
          <div className="flex flex-col gap-6">
            <Input
              type="string"
              name="otp"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMsg={formik.touched.email ? formik.errors.email : ""}
              placeholder="Enter Otp"
            />
          </div>

          <div className="mt-8 mb-4">
            <Button
              type="submit"
              disabled={isLoading}
              className="flex justify-center items-center"
              name="Submit"
            >
              {isLoading ? (
                <ThreeDots
                  height={80}
                  width="80"
                  radius="9"
                  color="#fff"
                  ariaLabel="three-dots-loading"
                  visible={true}
                />
              ) : (
                "Verify"
              )}
            </Button>
          </div>
        </form>
      </div>
    </DefaultLayout>
  )
}

export default Otp
