import { useState } from "react";
import { InfinitySpin, RotatingSquare } from "react-loader-spinner";

import { useSeniority } from "../../store/seniority.state";
import Plus from "../Icons/Plus";
import NavTab from "../NavTabs";
import {
  useCreateScheduleLabelMutation,
  useGetScheduleLabelsQuery,
} from "../../api/preferencesApi";
import RoundedCancelOutline from "../Icons/RoundedCancelOutline";
import RoundedTickOutline from "../Icons/RoundedTickOutline";
import handleResponse from "../../utils/handleResponse";
import LabelItem from "./LabelItem";

const ScheduleLabelItem = () => {
  const { seniorities, activeId, setActiveId } = useSeniority();

  const {
    data: scheduleLabels,
    refetch,
    isLoading,
  } = useGetScheduleLabelsQuery({
    seniority: activeId,
  });

  const [createScheduleLabel, { isLoading: isCreateLoading }] =
    useCreateScheduleLabelMutation();

  const [isAdd, setIsAdd] = useState(false);
  const [label, setLabel] = useState({ label: "", shortLabel: "" });

  const resetState = () => {
    setIsAdd(false);
    setLabel({ label: "", shortLabel: "" });
  };

  return (
    <div className="p-4 w-full h-full bg-gray rounded-md flex flex-col">
      <div className="font-bold text-2xl text-black">Labels</div>
      <div className="w-[50%] mx-auto mb-2">
        <NavTab
          tabs={seniorities}
          className={"bg-white"}
          tabType="neumorphism"
          activeId={activeId}
          onChange={(id) => {
            setActiveId(id);
            resetState();
          }}
        />
      </div>
      <div
        className={`p-4 flex-grow h-0 overflow-y-scroll small-scrollbar ${
          isLoading ? "flex justify-center items-center" : ""
        }`}
      >
        {isLoading ? (
          <InfinitySpin width="200" color="#67823A" />
        ) : (
          <>
            <table className="border-separate border-spacing-4 w-[50%]">
              <tbody>
                {scheduleLabels?.map(
                  (
                    label: {
                      label: string;
                      shortLabel: string;
                      colorCode: string;
                      _id: string;
                    },
                    index: number
                  ) => (
                    <LabelItem
                      key={label._id}
                      label={label}
                      index={index}
                      refetchScheduleLabels={() => {
                        refetch();
                      }}
                    />
                  )
                ) ?? []}
                {isAdd && (
                  <tr className="font-semibold">
                    <td className="flex items-center">
                      {isCreateLoading && (
                        <RotatingSquare height={"20px"} width={"20px"} />
                      )}{" "}
                      <div>{`${scheduleLabels.length + 1}.`}</div>
                    </td>
                    <td>
                      <input
                        className="outline-none w-36"
                        value={label.label}
                        disabled={isCreateLoading}
                        max={20}
                        onChange={(e) => {
                          const newShortLabel =
                            label.shortLabel.length < 4 && e.target.value.length > 1
                              ? e.target.value.slice(0, 3)
                              : e.target.value === ""
                              ? ""
                              : undefined;

                          setLabel({
                            ...label,
                            label: e.target.value,
                            ...(newShortLabel !== undefined
                              ? { shortLabel: newShortLabel }
                              : {}),
                          });
                        }}
                      />
                    </td>
                    <td></td>
                    <td className="border-l-2 pl-2 border-black">
                      <input
                        className="outline-none w-12"
                        value={label.shortLabel}
                        disabled={isCreateLoading}
                        max={2}
                        onChange={(e) =>
                          setLabel({ ...label, shortLabel: e.target.value })
                        }
                      />
                    </td>
                    <td></td>
                    <td className="flex gap-1">
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={() => {
                          resetState();
                        }}
                      >
                        <RoundedCancelOutline />
                      </div>
                      <div
                        className="flex items-center justify-between cursor-pointer"
                        onClick={async () => {
                          if (label.label !== "" && label.shortLabel !== "") {
                            const response = await createScheduleLabel({
                              label: label.label,
                              shortLabel: label.shortLabel,
                              seniority: activeId,
                            });

                            handleResponse(response, "Label added.", () => {
                              refetch();
                              resetState();
                            });
                          }
                        }}
                      >
                        <RoundedTickOutline />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {!isAdd && (
              <div
                className="bg-gray2 w-12 h-6 flex justify-center items-center rounded-lg cursor-pointer my-2"
                onClick={() => {
                  setIsAdd(true);
                }}
              >
                <Plus color={"white"} />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleLabelItem;
