import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { DateTime } from "luxon";

import DateBlock from "./DateBlock";
import { generateDates } from "../../utils/generateDates";
import { useLocation } from "../../store/location.store";
import { CarotLeft } from "../Icons/CarotLeft";
import { CarotRight } from "../Icons/CarotRight";
import { useEstimationContext } from "../../hooks/context/useEstimationContext";
import {
  sortShifts,
  useGetShifts,
  usePrefetchGetShifts,
} from "../../hooks/estimation/useGetShifts";
import { useSeniority } from "../../store/seniority.state";
import { shiftFindFn } from "../../utils/shiftFindFn";
import { useGetCalendarQuery, useGetLeavesQuery } from "../../api/locationApi";
import { TIME_ZONE } from "../../constants";
import { useRunOnChange } from "../../hooks/runOnChange";

type DateSliderProps = {
  activeDate: Date;
  prevDate: Date;
  nextDate: Date;
  className?: string;
};

const INIT_START_INDEX = 30;
const OFFSET = 30;

const PrevArrow = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className={`cursor-pointer absolute top-[50%] -left-10 bg-green1 rounded-md w-fit p-1`}
      onClick={onClick}
    >
      <CarotLeft />
    </div>
  );
};

const NextArrow = ({ onClick }: { onClick: () => void }) => {
  return (
    <div
      className={`cursor-pointer absolute top-[50%] -right-10 bg-green1 rounded-md w-fit p-1`}
      onClick={onClick}
    >
      <CarotRight />
    </div>
  );
};

const DateScroll = ({
  activeDate,
  prevDate,
  nextDate,
  className,
}: DateSliderProps) => {
  const [activeIndex, setActiveIndex] = useState(INIT_START_INDEX);
  const [dateList, _] = useState(generateDates(activeDate, OFFSET));
  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();
  const navigate = useNavigate();

  const { resetStates, triggerBarReset, setRequestTab, isOpenRequest } =
    useEstimationContext();

  const {
    data: leaveDates,
    isLoading: isLeaveLoading,
    isFetching: isLeaveFetching,
  } = useGetLeavesQuery(
    {
      locationId: activeLocationId,
      month: DateTime.fromJSDate(activeDate).setZone(TIME_ZONE).month,
      year: DateTime.fromJSDate(activeDate).setZone(TIME_ZONE).year,
    },
    { skip: !activeLocationId }
  );

  const {
    data: shiftData,
    isLoading: isShiftLoading,
    isFetching: isShiftFetching,
    refetch: refetchShifts,
  } = useGetShifts({ activeDate, prevDate, nextDate });

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 750,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: INIT_START_INDEX,
    arrows: true,
    centerPadding: "0px",
    centerMode: true,
    swipe: false,
    variableWidth: false,
    prevArrow: <PrevArrow onClick={() => undefined} />,
    nextArrow: <NextArrow onClick={() => undefined} />,
    beforeChange: (oldIndex: number, index: number) => {
      setActiveIndex(index);
      resetStates(activeSeniorityId);
      if (isOpenRequest) {
        const nextOrPrevShiftId =
          shiftData?.[oldIndex < index ? 2 : 0]?.sort(sortShifts)?.[0]?._id;
        setRequestTab(nextOrPrevShiftId ?? "dayOff");
      }
      const dateHash = window.btoa(dateList[index].getTime().toString());
      navigate(
        `/dashboard/${dateHash}?locationId=${activeLocationId}&seniority=${activeSeniorityId}`
      );
    },
  };

  const { refetch: refetchCalendar } = useGetCalendarQuery(
    {
      locationId: activeLocationId,
      month: activeDate.getMonth() + 1,
      year: activeDate.getFullYear(),
      seniority: activeSeniorityId,
    },
    { skip: !activeLocationId }
  );

  useRunOnChange(shiftData, (prevVal, value) => {
    if (prevVal && value) {
      const isScheduleFilledPrev: boolean[] = prevVal.map(
        (shifts: { slots: any[] }[]) =>
          shifts.some((shift: { slots: any[] }) =>
            shift.slots.some((slot) => !slot.schedule)
          )
      );

      const isScheduleFilled: boolean[] = value.map(
        (shifts: { slots: any[] }[]) =>
          shifts.some((shift: { slots: any[] }) =>
            shift.slots.some((slot) => !slot.schedule)
          )
      );
      
      if (
        isScheduleFilledPrev.some((bool, index) => {
          return bool !== isScheduleFilled[index];
        })
      ) {
        refetchCalendar();
      }
    }
  });

  const { triggerPrefetch: triggerRefetch } = usePrefetchGetShifts({
    activeDate,
  });

  useEffect(() => {
    if (activeIndex === OFFSET * 2 - 2 || activeIndex === 0) {
      window.location.reload();
    }
  }, [activeIndex]);

  return (
    <div className={`w-[90%] h-full ${className}`}>
      <Slider {...sliderSettings}>
        {dateList.map((date, index) => {
          const type =
            index === activeIndex - 1
              ? "dayBefore"
              : index === activeIndex
              ? "inFocus"
              : index === activeIndex + 1
              ? "dayAfter"
              : "dayAfter";
          return (
            <DateBlock
              key={index}
              triggerBarReset={triggerBarReset}
              isShiftsLoading={
                isShiftLoading ||
                isShiftFetching ||
                isLeaveLoading ||
                isLeaveFetching
              }
              date={date}
              leaveDates={leaveDates}
              shifts={
                shiftData?.find(
                  shiftFindFn({
                    date,
                    activeLocationId,
                    activeSeniorityId,
                  })
                ) ?? []
              }
              refetchShifts={async () => {
                refetchShifts(
                  type === "inFocus"
                    ? "active"
                    : type === "dayBefore"
                    ? "prev"
                    : type === "dayAfter"
                    ? "next"
                    : "all"
                );
                triggerRefetch();
              }}
              type={type}
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default DateScroll;
