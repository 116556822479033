import { useState } from "react";
import OuterWindow from "../OuterWindow";
import { IosTimePicker } from "../../IosTimePicker";
import { Minus } from "../../Icons/Minus";
import Plus from "../../Icons/Plus";
import { useEstimationContext } from "../../../hooks/context/useEstimationContext";
import {
  formatDateAPI,
  formatDisplayDate,
  formatTimeAPI,
} from "../../../utils/formatDate";
import {
  useCreateShiftMutation,
  useUpdateShiftMutation,
} from "../../../api/shiftsApi";
import { RotatingSquare } from "react-loader-spinner";
import useHandleSuccessErrors from "../../../hooks/handleSuccessErrors";
import { useLocation } from "../../../store/location.store";
import { useSeniority } from "../../../store/seniority.state";
import { useGetShiftsSingle } from "../../../hooks/estimation/useGetShifts";

type CreateNewShiftEditShiftWindowProps = {
  type: "create" | "edit";
  date: Date;
  shiftId?: string;
  time?: { from: string; to: string };
  slotsCountProp?: number;
  slotsFilled?: number;
};

const CreateNewShiftEditShiftWindow = ({
  type,
  date,
  shiftId,
  time,
  slotsCountProp,
  slotsFilled,
}: CreateNewShiftEditShiftWindowProps) => {
  const [slotsCount, setSlotCount] = useState<number>(
    type === "edit" ? slotsCountProp! : 1
  );

  const { activeId: activeLocationId } = useLocation();
  const { activeId: activeSeniorityId } = useSeniority();

  const { setResponsiveWindow, setTriggerBarReset } = useEstimationContext();

  const [fromTime, setFromTime] = useState(
    time ? (type === "edit" ? formatTimeAPI(time.from) : time.from) : "8:00 AM"
  );

  const [toTime, setToTime] = useState(
    time ? (type === "edit" ? formatTimeAPI(time.to) : time.to) : "2:00 PM"
  );

  const [
    createShift,
    {
      isLoading: isLoadingCreateShift,
      isSuccess: isCreateShiftSuccess,
      isError: isCreateShiftError,
      error: createShiftError,
    },
  ] = useCreateShiftMutation();

  const [
    updateShift,
    {
      isLoading: isUpdateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateShiftMutation();

  const { refetch: refetchShifts } = useGetShiftsSingle({
    activeDate: date,
  });

  const successFunction = async () => {
    await refetchShifts();
  };

  useHandleSuccessErrors({
    isSuccess: isCreateShiftSuccess,
    isError: isCreateShiftError,
    error: createShiftError,
    successMessage: "Shift created successfully !",
    successFunction,
  });

  useHandleSuccessErrors({
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
    error: updateError,
    successMessage: "Shift updated successfully !",
    successFunction,
  });

  return (
    <OuterWindow
      className="bg-secondary shadow-lg text-white h-full rounded-xl flex flex-col"
      headerClassName=" !justify-between px-4 gap-4"
      title={formatDisplayDate(date)}
      bodyClass=" flex flex-col h-full !pt-0 !pb-0 !bg-white !h-full flex-grow"
      headerChildNode={<></>}
      bodyChildNode={
        <div className="flex-grow flex justify-evenly flex-col space-y-2 pb-4  max-w-xs  mx-auto w-full">
          <div className="grid grid-cols-2 gap-x-3">
            <div className="col-span-2">
              <div className="font-medium text-sm text-black1 mb-3">
                {type === "create" ? "Create a new Shift" : "Edit Shift"}
              </div>
            </div>
            <div className="col-span-2">
              <div className="flex justify-between">
                <div>
                  <div className="font-bold text-black3 text-[10px] mb-2">
                    Start Time
                  </div>
                  <div className=" overflow-hidden">
                    <IosTimePicker
                      time={fromTime}
                      setTime={setFromTime}
                    />
                  </div>
                </div>
                <div>
                  <div className="font-bold text-black3 text-[10px] mb-2">
                    End Time
                  </div>
                  <div className="overflow-hidden">
                    <IosTimePicker
                      time={toTime}
                      setTime={setToTime}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-6">
            <div className="rounded-lg flex cursor-pointer">
              <div
                className="rounded-l-lg bg-primary border-l-2 border-y-2 border-r border-secondary px-3 py-1.5"
                onClick={() => {
                  if (slotsCount > (slotsFilled ?? 1)) {
                    setSlotCount(slotsCount - 1);
                  }
                }}
              >
                <Minus stroke={"stroke-secondary"} />
              </div>
              <div className="text-xs font-semibold text-black1 grow bg-white border-green1 border-x-[1.5px] border-y-2 items-center flex justify-center">
                Work Slot : {slotsCount}
              </div>
              <div
                className="rounded-r-lg bg-primary border-r-2 border-y-2 border-l border-secondary px-3 py-1.5 cursor-pointer"
                onClick={() => {
                  if (slotsCount < 8) {
                    setSlotCount(slotsCount + 1);
                  }
                }}
              >
                <Plus stroke="stroke-secondary" color={"white"} />
              </div>
            </div>
            <div className="flex justify-between gap-3 mt-2">
              {Array(slotsCount)
                .fill(0)
                .map((_, index) => (
                  <div
                    key={index}
                    className="bg-[#BDBDBD] h-0.5 flex-grow rounded-lg"
                  ></div>
                ))}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-x-3 ">
            <div className="col-span-1">
              <button
                className=" w-full ring !ring-primary rounded-lg px-4 py-1.5 font-bold text-secondary text-xs"
                disabled={isLoadingCreateShift || isUpdateLoading}
                onClick={() => {
                  setResponsiveWindow({ type: "dateView", props: {} });
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-span-1">
              <button
                className="text-white bg-secondary w-full ring ring-secondary rounded-lg px-4 py-1.5 font-bold text-xs text-center"
                disabled={isLoadingCreateShift || isUpdateLoading}
                onClick={async () => {
                  if (type === "create") {
                    await createShift({
                      time: { from: fromTime, to: toTime },
                      totalDoctorsRequired: slotsCount,
                      locationId: activeLocationId,
                      seniority: activeSeniorityId,
                      date: formatDateAPI(date),
                    });
                    setTriggerBarReset((triggerBarReset) => !triggerBarReset);
                  } else {
                    await updateShift({
                      shiftId,
                      time: { from: fromTime, to: toTime },
                      totalDoctorsRequired: slotsCount,
                    });
                  }
                  setResponsiveWindow({ type: "dateView", props: {} });
                }}
              >
                {isLoadingCreateShift || isUpdateLoading ? (
                  <div className="mx-auto w-fit">
                    <RotatingSquare height={"16px"} width={"16px"} />
                  </div>
                ) : (
                  `${type === "create" ? "Create" : "Edit"} Shift`
                )}
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default CreateNewShiftEditShiftWindow;
